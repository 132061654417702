<template>
  <q-drawer
    class="LeftPanel"
    behavior="default"
    :show-if-above="true"
    :breakpoint="1024"
    @hide="$emit('hide')"
    :model-value="open"
  >
    <q-list v-if="showDropDown">
      <SelectActiveEntity :config="config" />
      <LeftPanelMenuSection
        v-if="isParticipantCompany && !isUserContext"
        title="Depåinstitut"
        :showMenuSectionTitle="hasMultipleMenuSections"
        :menuItems="participantMenuItems"
      />
      <LeftPanelMenuSection
        v-if="isInsuranceCompany && !isUserContext"
        title="Försäkringsbolag"
        :showMenuSectionTitle="hasMultipleMenuSections"
        :menuItems="insuranceCompanyMenuItems"
      />
      <LeftPanelMenuSection
        v-if="isIssuer && !isUserContext"
        title="Emittent"
        :showMenuSectionTitle="hasMultipleMenuSections"
        :menuItems="menuItems"
      />
      <LeftPanelMenuSection
        v-if="isUserContext"
        title="Aktiebok"
        :showMenuSectionTitle="hasMultipleMenuSections"
        :menuItems="menuItems"
      />
      <LeftPanelMenuSection
        title="Övrigt"
        :showMenuSectionTitle="hasMultipleMenuSections"
        :menuItems="commonUserMenuTail"
      />
      <div class="md-hide lg-hide">
        <q-separator />
        <q-item clickable @click="logout" class="q-gutter-none">
          <q-item-section avatar class="q-pl-sm q-pr-none">
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section class="text-subtitle1">Logga ut</q-item-section>
        </q-item>
      </div>
    </q-list>
  </q-drawer>
</template>
<script lang="ts">
// @ts-nocheck
import store from '@/store';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import SelectActiveEntity from './SelectActiveEntity.vue';
import { Context } from '@/router';
import { EntityType } from '@shared/models/types';
import LeftPanelMenuSection from '@/components/LeftPanelMenuSection.vue';
import { MenuItem } from '@/models/MenuItem';

import { logout } from '@/models/profileService';

@Component({
  components: {
    SelectActiveEntity,
    LeftPanelMenuSection,
  },
})
export default class LeftPanel extends Vue {
  @Prop({ default: false }) open;

  get hasMultipleMenuSections() {
    if (this.isUserContext) {
      return false;
    }

    let count = 0;
    if (this.isIssuer) count++;
    if (this.isInsuranceCompany) count++;
    if (this.isParticipantCompany) count++;
    return count >= 2;
  }

  get showDropDown() {
    return store.activeEntity || this.isUserContext;
  }

  get config() {
    return {
      context: this.$route.meta.context,
      title: this.$route.meta.title,
    };
  }

  get isUserContext() {
    return this.config.context === Context.USER;
  }

  get activeEntity() {
    return store.activeEntity;
  }

  get isIssuer() {
    return this.activeEntity?.types?.includes(EntityType.issuer);
  }

  get isInsuranceCompany() {
    return this.activeEntity?.types?.includes(EntityType.insuranceCompany);
  }

  get isParticipantCompany() {
    return this.activeEntity?.types?.includes(EntityType.participant);
  }

  get entityType() {
    return this.isUserContext
      ? 'user'
      : this.activeEntity?.types?.includes(EntityType.issuer)
      ? EntityType.issuer
      : this.activeEntity?.types?.includes(EntityType.participant)
      ? EntityType.participant
      : this.activeEntity?.types?.includes(EntityType.insuranceCompany)
      ? EntityType.insuranceCompany
      : null;
  }

  get menuItems(): MenuItem[] {
    if (this.isUserContext) {
      return this.userMenuItems;
    }
    return this.companyMenuItems;
  }

  get participantMenuItems(): MenuItem[] {
    if (!this.activeEntity) {
      return [];
    }
    return [
      {
        title: 'Värdepapper',
        icon: 'icon-dashboard',
        url: `${this.activeEntity.baseUrl}/custodian`,
        disabled: false,
        show: true,
      },
    ];
  }

  get insuranceCompanyMenuItems(): MenuItem[] {
    if (!this.activeEntity) {
      return [];
    }
    return [
      {
        title: 'Värdepapper',
        icon: 'icon-dashboard',
        url: `${this.activeEntity.baseUrl}/insurer`,
        disabled: false,
        show: true,
      },
    ];
  }

  get companyMenuItems(): MenuItem[] {
    if (!this.activeEntity) {
      return [];
    }
    const locked = this.activeEntity.issuerData?.locked;
    const onlyWarrantCompany = this.activeEntity.issuerData?.onlyWarrantCompany;

    const createShareRegisterTitle =
      this.activeEntity.issuerData?.shareRegisterState === 'REQUEST_CHANGE' ? 'Komplettera aktiebok' : 'Skapa aktiebok';

    return [
      {
        title: createShareRegisterTitle,
        icon: 'icon-create',
        url: `${this.activeEntity.baseUrl}/skapa-aktiebok`,
        show: this.isIssuer && !locked,
        disabled: false,
      },
      {
        title: 'Dashboard',
        icon: 'icon-dashboard',
        url: `${this.activeEntity.baseUrl}/dashboard`,
        disabled: false,
        show: true,
      },
      {
        title: 'Aktiebok',
        icon: 'icon-stockbook',
        url: `${this.activeEntity.baseUrl}/aktiebok`,
        disabled: !locked,
        show: true,
      },
      {
        title: 'Optionsbok',
        icon: 'icon-stockbook',
        url: `${this.activeEntity.baseUrl}/optionsbok`,
        disabled: false,
        show: true,
      },
      {
        title: 'Skuldbok',
        icon: 'icon-stockbook',
        url: `${this.activeEntity.baseUrl}/skuldbok`,
        disabled: false,
        show: true,
      },
      {
        title: 'Investerarbok',
        icon: 'icon-investbook',
        url: `${this.activeEntity.baseUrl}/investerarbok`,
        disabled: !locked,
        show: true,
      },
      {
        title: 'Ägarbyte',
        icon: 'icon-exchange',
        url: `${this.activeEntity.baseUrl}/agarbyte`,
        disabled: !locked && !onlyWarrantCompany,
        show: true,
      },
      {
        title: 'Nyemission',
        icon: 'icon-newemission',
        url: `${this.activeEntity.baseUrl}/nyemission`,
        disabled: !locked,
        show: true,
      },
      {
        title: 'Emittera värdepapper',
        icon: 'icon-newemission',
        url: `${this.activeEntity.baseUrl}/vardepapper`,
        disabled: false,
        show: true,
      },
      {
        title: 'Split',
        icon: 'icon-newemission',
        url: `${this.activeEntity.baseUrl}/split`,
        disabled: !locked,
        show: true,
      },
      {
        title: 'Transaktioner',
        icon: 'icon-stockbook',
        url: `${this.activeEntity.baseUrl}/transaktioner`,
        disabled: !locked,
        show: true,
      },
      {
        title: 'Bolagsföreträdare',
        icon: 'icon-admin',
        url: `${this.activeEntity.baseUrl}/roller`,
        disabled: false,
        show: true,
      },
      {
        title: 'Bolagsstämma',
        icon: 'icon-admin',
        url: `${this.activeEntity.baseUrl}/bolagsstamma`,
        disabled: !locked,
        show: true,
      },
      {
        title: 'Aktieägartillskott',
        icon: 'icon-newemission',
        url: `${this.activeEntity.baseUrl}/aktieagartillskott`,
        disabled: false,
        show: true,
      },
    ];
  }

  get commonUserMenuTail(): MenuItem[] {
    let menuItems = [
      {
        title: 'Malldokument',
        icon: 'icon-download',
        url: '/avtalsmallar',
        disabled: false,
        show: true,
        testId: 'test-meny-mallar',
      },
    ];

    if (this.activeEntity) {
      menuItems.push({
        title: 'Administratörer',
        icon: 'icon-admin',
        url: `${this.activeEntity.baseUrl}/admin`,
        disabled: false,
        show: this.isIssuer || this.isParticipantCompany || this.isInsuranceCompany,
      });
    }
    return menuItems;
  }

  get userMenuItems() {
    return [
      {
        title: 'Mina innehav / Intressen',
        icon: 'icon-investbook',
        url: '/mina-innehav',
        disabled: false,
        show: this.isUserContext,
      },
      {
        title: 'Mina bolagsengagemang',
        icon: 'icon-investbook',
        url: '/mina-bolagsengagemang',
        disabled: false,
        show: this.isUserContext,
      },
      {
        title: 'Mina investeringserbjudanden',
        icon: 'icon-investbook',
        url: '/investment-offer',
        disabled: false,
        show: this.isUserContext,
      },
      {
        title: 'Transaktioner',
        icon: 'icon-investbook',
        url: '/mina-transaktioner',
        disabled: false,
        show: this.isUserContext,
      },
      {
        title: 'Inställningar',
        icon: 'icon-investbook',
        url: '/mina-inst%C3%A4llningar',
        disabled: false,
        show: this.isUserContext,
      },
    ];
  }

  async logout() {
    await logout();
    await this.$router.push({ path: '/' });
  }
}
</script>
<style lang="scss">
.LeftPanel {
  margin: 0 auto;
  .text-subtitle1 {
    line-height: 1rem;
  }
  .q-list {
    .q-item__label--header {
      color: $dark;
      font-size: 14px;
    }
    .q-item {
      min-height: 38px;
      &.q-item--active {
        .q-item__section {
          color: $secondary;
        }
      }
      .q-item__section {
        font-size: 14px;
        color: $dark;
        &.q-item__section--avatar {
          min-width: 30px;
        }
        .q-icon {
          font-size: 17px;
        }
      }
      &.disabled {
        opacity: 1 !important;
        .q-item__section {
          color: $grey-3;
        }
      }
      &.is-active {
        .q-item__section {
          color: $secondary;
        }
      }
    }
    .q-separator {
      margin: 0 0 16px;
    }
  }
  .q-btn-dropdown {
    min-height: 80px !important;
    .q-btn__content {
      line-height: 18px;
      white-space: normal;
      text-align: left;
      span {
        width: 100%;
      }
    }
    .q-btn:hover {
      .q-focus-helper {
        background: #fff !important;
      }
    }
  }
}
</style>
