import { Entity } from '@shared/web/models';
import { newModel } from '@shared/web/models/base';
import { modelHandler } from '@shared/web/models/base';
import { isBackOffice } from '@shared/web/utils';
import { getObject, addModel } from '@shared/web/store';
import axios from 'axios';
import { asModelId } from '@shared/utils';
const baseUrl = `/${isBackOffice() ? 'api' : 'webapi'}/entity`;
const { findModel, saveModel, searchModels } = modelHandler(Entity, baseUrl);

export async function search(query, defaults = {}): Promise<Array<Entity>> {
  const result = await searchModels({ ...defaults, ...query });
  return result;
}
export async function findEntity(id: Entity | string) {
  return findModel(asModelId(id));
}

export async function saveEntity(entity: Entity) {
  const { model } = await saveModel(entity);

  return model;
}

export async function findEntityWithRouteParams(params: any) {
  return await findEntityWithCountryCode(params.countryCode as string, params.entity as string);
}

export async function loadEntityWithRouteParams(params: any) {
  return await findEntityWithCountryCode(params.countryCode as string, params.entity as string, true);
}

export async function findEntityWithCountryCode(countryCode: string, id: string, forceUpdate = false) {
  const storeId = countryCode + id;
  const entity = getObject(storeId);
  if (entity && !forceUpdate) {
    return entity;
  }

  return await fetchEntityWithCountryCode(countryCode, id, storeId);
}

async function fetchEntityWithCountryCode(countryCode: string, id: string, storeId: string) {
  const { data } = await axios.get(baseUrl + '/' + countryCode + '/' + id);
  const loadedEntity = Entity.newModel(data, true);
  addModel(loadedEntity, storeId);
  return loadedEntity;
}

export async function fetchEntityOwners(entity: Entity) {
  const { data } = await axios.get(baseUrl + `/${entity.id}/owners`);
  return Entity.newModels(data, true);
}

export async function lookupEntity(query) {
  const { data } = await axios.post(baseUrl + '/lookup', query);
  return newModel(data, Entity, true);
}
