import PersonNummer from 'personnummer';
import orgnrValidator from 'se-orgnr-validator';
import formatStringByPattern from 'format-string-by-pattern';
import type { IEntity } from '@shared/models/modelTypes';
import { sortBy } from 'lodash';
import { EntityAdminRole, EntityType, Products, RoleType } from '../models/types';
import { InstrumentCategory, Reservation, InstructionState, TransactionType } from '@shared/models/types';
import { countryOptions, currencyOptions } from '@shared/utils/localeInfo';
import { Entity } from '@shared/web/models';
import { instrumentGroupOptions, toInstrumentGroupLabel, toInstrumentId } from './instrumentGroups';
import { default as validation } from '@shared/utils/validation';

export { validation, toInstrumentGroupLabel, toInstrumentId };

const { SHA, RIGHTS, DEBT, SHAREHOLDER_CONTRIBUTION } = InstrumentCategory;
const { PRE_EMPTION, REFUSAL_CLAUSE, CONSENT_SUBJECT, OTHER_CLAUSE, FOR_ALL } = Reservation;
const { NEW, IN_PROGRESS, EXECUTED, REQUEST_CHANGE, REVIEW, SUBMITTED } = InstructionState;

const {
  TRADE,
  GIFT,
  INHERITED,
  INSURANCE_PURCHASE,
  INSURANCE_REPURCHASE,
  PLEDGE,
  EXERCISE_PLEDGE,
  SECURITIES_DIVIDEND,
} = TransactionType;

const reservations = {
  PRE_EMPTION: 'Hembud',
  REFUSAL_CLAUSE: 'Förköp',
  CONSENT_SUBJECT: 'Samtycke',
  OTHER_CLAUSE: 'Annat förbehåll',
  FOR_ALL: 'Förbehåll gäller alla ägare',
};

export const instructionStates = {
  NEW: 'Preliminär',
  SUBMITTED: 'Inskickad',
  REVIEW: 'Under granskning',
  REQUEST_CHANGE: 'Begärd komplettering',
  EXECUTED: 'Genomförd',
  EXECUTED_INTERIM: 'Genomförd (BTA skapad)',
  EXECUTED_WAITING: 'Genomförd (framtida)',
  IN_PROGRESS: 'Pågående',
  CORRECTED: 'Korrigerad',
  REVERTED: 'Makulerad',
};

export const transactionTypes = {
  TRADE: 'Försäljning',
  GIFT: 'Gåva',
  INHERITED: 'Arv',
  INSURANCE_REPURCHASE: 'Återköp försäkring',
  INSURANCE_PURCHASE: 'Införsäljning försäkring',
  RIGHTS_ISSUE: 'Nyemission bef aktieslag',
  ISSUE_SHARE: 'Nyemission nytt aktieslag',
  ISSUE_CONVERTIBLE: 'Emittera konvertibel',
  ISSUE_BOND: 'Emittera obligation',
  SPLIT: 'Split',
  SHAREREGISTER_CREATED: 'Aktiebok skapad i Kapclear',
  SHAREREGISTER_CLOSED: 'Stäng ner aktiebok',
  INSTRUMENT_CLOSED: 'Stäng ner värdepapper',
  ISSUE_WARRANT: 'Teckna option',
  EXERCISE_WARRANT: 'Lös option',
  EXERCISE_CONVERTIBLE: 'Stänga konvertibel',
  EXERCISE_BOND: 'Stänga obligation',
  SETTLED: 'Byte BTA till aktie',
  EXPIRED: 'Förfallen',
  PLEDGE: 'Pantsättning',
  KAPSURE_ISSUER_CREATED: 'Kapsure emittent skapad',
  KAPSURE_ISSUER_REMOVED: 'Kapsure emittent borttagen',
  KAPSURE_BOOKING: 'Inbokning',
  KAPSURE_SETTLEMENT: 'Återköp',
  KAPSURE_RIGHTS_ISSUE: 'Teckna nyemission',
  KAPSURE_BANKRUPTCY: 'Konkurs',
  KAPSURE_DVP_BUY: 'DVP Köp',
  KAPSURE_DVP_SELL: 'DVP Sälj',
  EXERCISE_PLEDGE: 'Lös pantsättning',
  ISSUE_DEBENTURE: 'Emittera vinstandelslån',
  SUPPLEMENT: 'Komplettering',
  EXERCISE_DEBENTURE: 'Stänga vinstandelslån',
  SECURITIES_DIVIDEND: 'Värdepappersutdelning',
  CORRECTION: 'Korrigering',
  ISSUE_SHAREHOLDER_CONTRIBUTION: 'Registrera aktieägartillskott',
  'bfs-sync': 'BFS sync',

  debit: {
    TRADE: 'Köp',
    GIFT: 'Mottagen gåva',
  },
  credit: {
    TRADE: 'Sälj',
    GIFT: 'Gåva',
  },
};

export const kapsureCounterparts = {
  Pareto: 'Pareto',
  Monitor: 'Monitor',
  Pepins: 'Pepins',
  SV_Ventures: 'SV Ventures',
  Person: 'Privatperson',
  Company: 'Företag',
  Other: 'Annan aktör',
};

const instructionTypeOptions = toTransactionTypeOptions([
  TRADE,
  GIFT,
  INHERITED,
  INSURANCE_PURCHASE,
  INSURANCE_REPURCHASE,
  PLEDGE,
  EXERCISE_PLEDGE,
  SECURITIES_DIVIDEND,
]);

export function toTransactionTypeOptions(types: Array<TransactionType>) {
  return types.map(typeValue => ({
    value: typeValue,
    label: transactionTypes[typeValue],
  }));
}

export function toInstructionStateLabel(instructionState: InstructionState) {
  return instructionStates[instructionState];
}

export function toTransactionTypeLabel(transactionType: TransactionType, debit?: boolean) {
  if (debit != null) {
    const debitLabel = debit ? transactionTypes.debit[transactionType] : transactionTypes.credit[transactionType];
    if (debitLabel) {
      return debitLabel;
    }
  }
  return transactionTypes[transactionType];
}

const instructionStateOptions = [NEW, IN_PROGRESS, EXECUTED].map(typeValue => ({
  value: typeValue,
  label: instructionStates[typeValue],
}));

const shareRegisterStateOptions = [
  { value: null, label: 'Alla' },
  ...[NEW, SUBMITTED, REVIEW, REQUEST_CHANGE, EXECUTED].map(typeValue => ({
    value: typeValue,
    label: instructionStates[typeValue],
  })),
];

export const reservationsOptions = [PRE_EMPTION, REFUSAL_CLAUSE, CONSENT_SUBJECT, OTHER_CLAUSE, FOR_ALL].map(
  typeValue => ({
    value: typeValue,
    label: reservations[typeValue],
  }),
);
export const positionReservationsOptions = [PRE_EMPTION, REFUSAL_CLAUSE, CONSENT_SUBJECT].map(typeValue => ({
  value: typeValue,
  label: reservations[typeValue],
}));

const roleTypeLabels = {
  [RoleType.AuthorizedSignatory]: 'Firmatecknare',
  [RoleType.Accountant]: 'Revisor',
  [RoleType.AlternativePrinciple]: 'Alternativ Huvudman',
  [RoleType.BoardMember]: 'Ledamot',
  [RoleType.CEO]: 'Verkställande direktör',
  [RoleType.Chairman]: 'Ordförande',
  [RoleType.RealPrinciple]: 'Verklig Huvudman',
  [RoleType.InvestorManager]: 'Investeringsansvarig',
};

export const booleanOptions = [
  { value: true, label: 'Ja' },
  { value: false, label: 'Nej' },
];

export const booleanAndAllOptions = [
  { value: null, label: 'Alla' },
  { value: true, label: 'Ja' },
  { value: false, label: 'Nej' },
];

export const fieldOptions = [
  { value: null, label: 'Alla' },
  { value: 'name', label: 'Namn' },
  { value: 'nationalId', label: 'Nationellt bolagsnr' },
];

const entityTypeLabels = {
  [EntityType.issuer]: 'Emittent',
  [EntityType.kapsureIssuer]: 'Kapsure Emittent',
  [EntityType.investor]: 'Investerare',
  [EntityType.insuranceCompany]: 'Försäkringsbolag',
  [EntityType.participant]: 'Depåinstitut',
  [EntityType.parentCompany]: 'Moderbolag',
  [EntityType.insuranceOwner]: 'Försäkringsägare',
};

export const typeOptions = [
  { value: null, label: 'Alla' },
  ...Object.entries(entityTypeLabels).map(([value, label]) => ({
    value,
    label,
  })),
];

export const productOptions = [
  { value: null, label: 'Alla' },
  { value: Products.KAPCLEAR, label: 'Kapclear' },
  { value: Products.KAPSURE, label: 'Kapsure' },
];

export const instrumentIdTypes: Array<{ label: string; value: string; readonly?: true }> = [
  { label: 'ISIN', value: 'ISIN' },
  { label: 'KapID', value: 'KapID', readonly: true },
  { label: 'Did', value: 'Did' },
  { label: 'CIC', value: 'Cic', readonly: true },
];

export const shareClassTypes = {
  A: 'A-aktie',
  B: 'B-aktie',
  C: 'C-aktie',
  D: 'D-aktie',
};

const shareClassTypeOptions = ['A', 'B', 'C', 'D'].map(typeValue => ({
  value: typeValue,
  label: shareClassTypes[typeValue],
}));

export function formatNationalId(entity: IEntity, showSecrecyMarked = true): string | null {
  if (entity == null) {
    return null;
  }
  if (entity.nationalId == null) {
    return '';
  }

  let nationalId = entity.nationalId;

  if (entity.countryCode === 'SE' && /^\d+$/.test(nationalId)) {
    if (nationalId.length === 12) {
      nationalId = formatStringByPattern('XXXXXXXX-XXXX', nationalId);
    }
    if (nationalId.length === 10) {
      nationalId = formatStringByPattern('XXXXXX-XXXX', nationalId);
    }
  }
  return `${nationalId}${
    showSecrecyMarked && entity.person?.secrecyMarked ? ' (skyddad identitet / samordningsnummer)' : ''
  }`;
}

export function toShareClassTypeLabel(typeValue: keyof typeof shareClassTypes) {
  return shareClassTypes[typeValue];
}

export function toTypeLabel(typeValue: keyof typeof entityTypeLabels) {
  return entityTypeLabels[typeValue];
}

export function toRoleTypeLabel(roleType: RoleType) {
  return roleTypeLabels[roleType];
}
export function toReservationLabel(key) {
  return reservations[key];
}

export function formatTypeList(types) {
  return types.map(typeId => toTypeLabel(typeId)).join(', ');
}

export function toBooleanLabel(value) {
  return value ? 'Ja' : 'Nej';
}

export function toShareRegisterStatusText(legalEntity: Entity, valid?: boolean, validNonPaySha?: boolean) {
  if (!legalEntity.isKapclearIssuer) {
    return null;
  }
  const shareRegisterState = legalEntity.issuerData?.shareRegisterState;
  const shareRegisterValid = legalEntity.issuerData?.shareRegisterValid;
  valid ??= shareRegisterValid;
  validNonPaySha ??= shareRegisterValid;

  if (shareRegisterState === InstructionState.REQUEST_CHANGE) {
    return instructionStates.REQUEST_CHANGE + (valid ? '' : ' (felaktig)');
  }

  if (shareRegisterState === InstructionState.REVIEW) {
    return instructionStates.REVIEW + (valid ? '' : ' (felaktig)');
  }

  if (shareRegisterState === InstructionState.NEW) {
    return instructionStates.NEW;
  }

  if (shareRegisterState === InstructionState.EXECUTED) {
    if (!valid && validNonPaySha) {
      return 'Genomförd men inte uppdaterad på bolagsverket';
    }
    return instructionStates.EXECUTED + (valid ? '' : ' (felaktig)');
  }

  if (shareRegisterState === InstructionState.SUBMITTED) {
    return instructionStates.SUBMITTED + (valid ? '' : ' (felaktig)');
  }

  return null;
}

const instrumentCategoryLabels = {
  SHA: 'Aktie',
  RIGHTS: 'Rätt',
  DEBT: 'Skuld',
  SHAREHOLDER_CONTRIBUTION: 'Aktieägartillskott',
};

export function isValidNationalId(nationalId) {
  return isValidPersonalNumber(nationalId) || orgnrValidator(nationalId);
}
export function isValidPersonalNumber(nationalId) {
  return PersonNummer.valid(nationalId);
}

export function toInstrumentCategoryLabel(value) {
  return instrumentCategoryLabels[value];
}

export const instrumentCategoryOptions = [SHA, RIGHTS, DEBT, SHAREHOLDER_CONTRIBUTION].map(value => ({
  value,
  label: instrumentCategoryLabels[value],
}));

export const options = {
  booleanAndAllOptions,
  booleanOptions,
  fieldOptions,
  typeOptions,
  reservationsOptions,
  positionReservationsOptions,
  countryOptions,
  currencyOptions,
  instrumentGroupOptions,
  instrumentCategoryOptions,
  shareClassTypeOptions,
  instructionStateOptions,
  shareRegisterStateOptions,
  instructionTypeOptions,
  productOptions,
};

export function toTransactionTypesLabel(transactionType: TransactionType, debit?: boolean) {
  if (debit != null) {
    const debitLabel = debit ? transactionTypes.debit[transactionType] : transactionTypes.credit[transactionType];
    if (debitLabel) {
      return debitLabel;
    }
  }
  return transactionTypes[transactionType];
}

export function cleanNationalId(nationalId: string) {
  return nationalId.replace(/\s/g, '').replace('-', '');
}

export function formatPersonalNumber(personalNumber?) {
  return formatStringByPattern('XXXXXXXX-XXXX', personalNumber);
}

export const entityAdminRoleLabels = {
  [EntityAdminRole.AuthorizedSignatory]: 'Firmatecknare',
  [EntityAdminRole.Accountant]: 'Revisor',
  [EntityAdminRole.CustodianAdmin]: 'Administratör Depåinstitut',
  [EntityAdminRole.Unspecified]: 'Annat',
};

export function toEntityAdminRoleLabel(entityAdminRole: EntityAdminRole) {
  return entityAdminRoleLabels[entityAdminRole];
}

export const roleTypesOrder = [
  RoleType.Chairman,
  RoleType.CEO,
  RoleType.BoardMember,
  RoleType.AuthorizedSignatory,
  RoleType.RealPrinciple,
  RoleType.AlternativePrinciple,
  RoleType.InvestorManager,
  RoleType.Accountant,
];

export function highestRole(roles: RoleType[]): RoleType {
  return sortBy(
    roles.map(role => ({ role, index: roleTypesOrder.indexOf(role) })),
    'index',
  )[0].role;
}
